import { Grid, Typography } from "@material-ui/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CancelButton, ForwardButton } from "../../../components/Primitives/Buttons";
import { useDialogStyles } from "./dialog.styles";

interface IFormButtonRow {
  isAcceptVisible?: boolean;
  isCancelVisible?: boolean;
  isCompleteVisible?: boolean;
  alignButtons?: "center" | "space-between";
  isLoading?: boolean;
  acceptTextOverride?: string;
  cancelTextOverride?: string;
  completeTextOverride?: string;
  onCancelClick?: VoidFunction;
  onAcceptClick?: VoidFunction;
  onCompleteClick?: VoidFunction;
  acceptDisabled?: boolean;
  type: "submit" | "button" | "reset";
}

export const AppDialogButtonRow: FC<IFormButtonRow> = (props) => {
  const {
    isAcceptVisible = true,
    isCancelVisible = true,
    isCompleteVisible = false,
    onCancelClick,
    onAcceptClick,
    onCompleteClick,
    cancelTextOverride,
    acceptTextOverride,
    completeTextOverride,
    isLoading,
    acceptDisabled,
    alignButtons = "center",
    type,
  } = props;
  const classes = useDialogStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} justifyContent={alignButtons} className={classes.buttonContainer}>
      {onCancelClick && isCancelVisible && (
        <Grid item>
          <CancelButton data-testid="app-dialog-cancel-button" onClick={onCancelClick} disabled={isLoading}>
            <Typography variant="body1">{cancelTextOverride ?? t("general.cancel.text")}</Typography>
          </CancelButton>
        </Grid>
      )}
      {onAcceptClick && isAcceptVisible && (
        <Grid item>
          <ForwardButton
            data-testid="app-dialog-accept-button"
            onClick={onAcceptClick}
            type={type}
            disabled={isLoading || acceptDisabled}
          >
            <Typography variant="body1">{acceptTextOverride ?? t("general.continue.text")}</Typography>
          </ForwardButton>
        </Grid>
      )}
      {onCompleteClick && isCompleteVisible && (
        <Grid item>
          <ForwardButton
            data-testid="app-dialog-complete-button"
            onClick={onCompleteClick}
            type={type}
            disabled={isLoading || acceptDisabled}
          >
            <Typography variant="body1">{completeTextOverride ?? t("general.continue.text")}</Typography>
          </ForwardButton>
        </Grid>
      )}
    </Grid>
  );
};
