import { Grid, LinearProgress, makeStyles, Theme, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { FC, useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Lightbox } from "../../../../../components/lightbox/lightbox.component";
import SearchField from "../../../../../components/SearchField";
import { MIN_COUNT_FOR_SEARCH_FIELD } from "../../../../../utils/constants";
import { usePackageIconAndLabel } from "../../../../domain/hooks/use-package-icon-and-label";
import { ICfmPackage, sortPackages } from "../../../../domain/packages/cfm-package";
import { useGetPackagesForProduct } from "../../../../repositories/queries/package/get-packages-for-product.query";
import { useOrderNewContext, WizardStep } from "../../order-new.provider";
import { OrderNewPackageDialog } from "../dialog/order-new-package-dialog.component";
import { PackageCustomImage } from "../info-box/package-custom-image.component";
import { PackageInfoBox } from "../info-box/package-info-box.component";
import { IOrderNewWizardFormInputs } from "../order-new-wizard.component";
import { WizardStepHeader } from "../wizard-step-heading.component";
import dayjs from "dayjs";

const useStyles = makeStyles((theme: Theme) => ({
  searchContainer: {
    marginTop: 25,
  },
  packageBoxFirst: {
    marginTop: 40,
  },
  packageBox: {
    marginTop: 5,
  },
  defaultIcon: {
    minWidth: 70,
  },
}));

interface IOrderNewPackageFormProps {
  handleAccept: VoidFunction;
  handleCancel?: VoidFunction;
  productOrderIndex?: number;
}

export const OrderNewPackageList: FC<IOrderNewPackageFormProps> = (props) => {
  const { handleAccept, handleCancel, productOrderIndex } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string | undefined>();
  const [isPackageModalOpen, setIsPackageModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<ICfmPackage | undefined>();
  const [selectedPackageForImageDialog, setSelectedPackageForImageDialog] = useState<ICfmPackage | undefined>();
  const { setActiveStep, resetProduct } = useOrderNewContext();
  const { watch, setValue, control, clearErrors, getValues } = useFormContext<IOrderNewWizardFormInputs>();
  const { remove, append, update } = useFieldArray({ name: "productOrders", control });
  const { enqueueSnackbar } = useSnackbar();
  const { getIcon } = usePackageIconAndLabel();

  const productId = watch("productId");
  const productOrders = watch("productOrders");
  const isEditing = productOrderIndex !== undefined;

  const { isLoading, data: packages, refetch } = useGetPackagesForProduct(productId, searchText);

  const packagesToShow = packages
    ?.sort(sortPackages)
    .filter((p) => !productOrders.some((orderPackage) => orderPackage.package?.packageId === p.id));

  useEffect(() => {
    if (!productOrderIndex) return;
    const productOrder = productOrders?.[productOrderIndex];

    if (!productOrder.package) return;
    const productPackage = packages?.find((productPackage) => productPackage.id === productOrder.package?.packageId);

    clearErrors();
    update(productOrderIndex, productOrder);
    setSelectedPackage(productPackage);
    setIsPackageModalOpen(true);
  }, [productOrders, clearErrors, packages, getValues, productOrderIndex, update]);

  useEffect(() => {
    refetch();
  }, [searchText, refetch]);

  const packageHeader = useMemo(() => {
    return productOrderIndex && productOrderIndex > 0
      ? t("orders.new.wizard.package.selectAdditional")
      : t("orders.new.wizard.package.select");
  }, [productOrderIndex, t]);

  if (!productId) {
    const errorMsg = t("orders.new.wizard.product.noId");
    enqueueSnackbar(t("general.error_occurred", { errorCode: 404, errorMsg }), { variant: "error" });
    resetProduct(setValue);
    setActiveStep(WizardStep.Product);
    return null;
  }

  const doDisplaySearchField = () => {
    return (packages?.length ?? 0) >= MIN_COUNT_FOR_SEARCH_FIELD || searchText;
  };

  const hasNoData = () => {
    return !isLoading && (packagesToShow?.length ?? 0) === 0;
  };

  const onPackageClick = (productPackage: ICfmPackage) => {
    setSelectedPackage(productPackage);
    const productOrder = getValues().productOrders?.[0];
    if (isEditing) {
      update(productOrderIndex, {
        ...productOrder,
        requestedPickupDate: productOrder.requestedPickupDate ?? dayjs().add(15, "days").toDate(),
        package: {
          packageId: productPackage.id,
          length: productOrder.package?.length ?? productPackage.length ?? undefined,
          height: productOrder.package?.height ?? productPackage.height ?? undefined,
          width: productOrder.package?.width ?? productPackage.width ?? undefined,
          weight: productOrder.package?.weight,
          measurementsRequired: productPackage.measurementsRequired,
          containersToBeCollected: productOrder.package?.containersToBeCollected,
          containersToBeDelivered: productOrder.package?.containersToBeDelivered,
          tareWeightKg: productOrder.package?.tareWeightKg ?? productPackage.tareWeightKg ?? null,
          averageGrossWeightKg:
            productOrder.package?.averageGrossWeightKg ?? productPackage.averageGrossWeightKg ?? null,
          withPackageDelivery: productPackage.withPackageDelivery,
        },
      });
    } else {
      append({
        ...productOrder,
        requestedPickupDate: dayjs().add(15, "days").toDate(),
        productGrossWeight: undefined,
        productNetWeight: undefined,
        package: {
          packageId: productPackage.id,
          length: productPackage.length ?? undefined,
          height: productPackage.height ?? undefined,
          width: productPackage.width ?? undefined,
          weight: undefined,
          measurementsRequired: productPackage.measurementsRequired,
          containersToBeCollected: undefined,
          containersToBeDelivered: undefined,
          tareWeightKg: productPackage.tareWeightKg ?? null,
          averageGrossWeightKg: productPackage.averageGrossWeightKg ?? null,
          withPackageDelivery: productPackage.withPackageDelivery,
        },
      });
    }

    clearErrors();
    setIsPackageModalOpen(true);
  };

  const onAccept = () => {
    setSelectedPackage(undefined);
    setIsPackageModalOpen(false);
    handleAccept();
  };

  const onCancel = () => {
    setSelectedPackage(undefined);
    if (!isEditing) {
      remove(productOrders.length - 1);
    }

    if (isEditing && productOrderIndex === 0) {
      const productOrder = getValues().productOrders?.[0];
      update(productOrderIndex, {
        ...productOrder,
        package: undefined,
      });
      setValue(`productOrders.${productOrderIndex}.productGrossWeight`, undefined);
      setValue(`productOrders.${productOrderIndex}.productNetWeight`, undefined);
    }

    setIsPackageModalOpen(false);

    if (!handleCancel) return;
    handleCancel();
  };

  const getCustomImageComponent = (pkg: ICfmPackage) => {
    if (!pkg.imageUrl) {
      return <div className={classes.defaultIcon}>{getIcon(pkg)}</div>;
    }

    return (
      <PackageCustomImage
        imageUrl={pkg.imageUrl}
        packageName={pkg.name ?? ""}
        onMouseEnter={() => setSelectedPackageForImageDialog(pkg)}
      />
    );
  };

  return (
    <>
      {!selectedPackageForImageDialog && isPackageModalOpen && (
        <OrderNewPackageDialog
          open={isPackageModalOpen}
          productPackage={selectedPackage}
          productOrderIndex={productOrders.length - 1}
          onCancel={onCancel}
          onAccept={onAccept}
        />
      )}
      {selectedPackageForImageDialog && selectedPackageForImageDialog.imageUrl && !isPackageModalOpen && (
        <Lightbox
          src={selectedPackageForImageDialog.imageUrl}
          title={selectedPackageForImageDialog.name ?? "-"}
          onClose={() => setSelectedPackageForImageDialog(undefined)}
        />
      )}
      <Grid container direction="column">
        <Grid item>
          <WizardStepHeader text={packageHeader} />
        </Grid>
        {isLoading && (
          <Grid item>
            <LinearProgress />
          </Grid>
        )}
        {doDisplaySearchField() && (
          <Grid item className={classes.searchContainer}>
            <SearchField
              size="large"
              tooltip={t("orders.new.wizard.search.packages")}
              onSearchSubmit={(value: string) => {
                setSearchText(value);
              }}
              placeholder={t("orders.new.wizard.search.packages")}
              autoFocus={false}
            />
          </Grid>
        )}

        {!isLoading &&
          packagesToShow &&
          packagesToShow.map((productPackage, index) => {
            return (
              <Grid key={productPackage.id} item className={index === 0 ? classes.packageBoxFirst : classes.packageBox}>
                <PackageInfoBox
                  productPackage={productPackage}
                  customIconComponent={getCustomImageComponent(productPackage)}
                  onClick={onPackageClick}
                />
              </Grid>
            );
          })}
        {hasNoData() && (
          <Grid item className={"mt-3 mb-2"}>
            <Typography variant="body1">{t("orders.new.wizard.noDataPackage")}</Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};
