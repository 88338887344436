import { Grid, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import _ from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useAsyncEffect from "use-async-effect";
import SearchField from "../../../../../components/SearchField";
import {
  CfmProductArticleType,
  EMPTY_PRODUCT_CONFIG_FILTER,
} from "../../../../../configurator/components/product/product.utils";
import {
  ProductOverviewGeneralFilter,
  ProductOverviewSortingFilter,
} from "../../../../../configurator/pages/product/components/product-overview.utils";
import { NewOrderToolTip } from "../../../../../shared/components/tooltips/order-new-tooltip.component";
import { useScrolledToBottomContext } from "../../../../../shared/domain/scroll/scrolled-to-bottom-context";
import { getNextProductPageParam } from "../../../../../shared/util/infinite-query-product.util";
import { ARTICLE_SEPARATOR, MIN_COUNT_FOR_SEARCH_FIELD } from "../../../../../utils/constants";
import { ICfmProduct } from "../../../../domain/products/cfm-product";
import { CfmProductConverter } from "../../../../repositories/models/converter/cfm-product.converter";
import { useGetProductsInfiniteQuery } from "../../../../repositories/queries/product/query/get-infinite-products.query";
import { useOrderNewContext, WizardStep } from "../../order-new.provider";
import { IInfoBoxDisplayData, ProductInfoBox } from "../info-box/product-info-box.component";
import { IOrderNewWizardFormInputs } from "../order-new-wizard.component";
import { WizardStepHeader } from "../wizard-step-heading.component";

const useStyles = makeStyles(() => ({
  searchContainer: {
    marginTop: 25,
    marginBottom: 35,
  },
  productContainer: {
    marginTop: 5,
  },
  mobileAlign: {
    textAlign: "center",
  },
}));

export const OrderNewProductList: FC = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setValue, getValues } = useFormContext<IOrderNewWizardFormInputs>();
  const [searchText, setSearchText] = useState<string | undefined>();

  const { data, isLoading, refetch, fetchNextPage, hasNextPage } = useGetProductsInfiniteQuery(
    {
      general: [ProductOverviewGeneralFilter.WithValidConfiguration, ProductOverviewGeneralFilter.Active],
      productConfig: EMPTY_PRODUCT_CONFIG_FILTER,
      routingRequired: undefined,
      searchText: searchText,
      sorting: ProductOverviewSortingFilter.ArticleNumber,
    },
    getNextProductPageParam,
    getValues("customerGroupId"),
  );
  const products = useMemo(() => {
    const pages = data?.pages ?? [];
    return _.flatMap(pages, (page) => page.products).map((product) => CfmProductConverter.toDomain(product));
  }, [data?.pages]);

  const { setActiveStep } = useOrderNewContext();
  const { hasScrolledToBottom } = useScrolledToBottomContext();

  useEffect(() => {
    refetch();
  }, [searchText, refetch]);

  useAsyncEffect(async () => {
    const shouldFetchNext = hasScrolledToBottom && hasNextPage && !isLoading;
    if (!shouldFetchNext) return;
    await fetchNextPage();
    // only refetch when user scrolled to bottom
  }, [fetchNextPage, hasScrolledToBottom, hasNextPage, isLoading]);

  const hasNoData = () => {
    return !isLoading && products.length === 0;
  };

  const doDisplaySearchField = () => {
    return products.length > MIN_COUNT_FOR_SEARCH_FIELD || searchText || hasNextPage;
  };

  const onProductClicked = (product: ICfmProduct) => {
    setValue("productId", product.id);
    setValue(`productOrders.0`, {
      requestedPickupDate: dayjs().add(15, "days").toDate(),
      orderComment: undefined,
      productNetWeight: undefined,
      orderNumber: undefined,
      package: undefined,
      productGrossWeight: undefined,
    });
    if (product.articleType === CfmProductArticleType.Product) {
      setActiveStep(WizardStep.Package);
    } else {
      setActiveStep(WizardStep.SubmitPage);
    }
  };

  const getProductDisplayData = (product: ICfmProduct): IInfoBoxDisplayData => {
    const text = `${product.articleNumber} ${ARTICLE_SEPARATOR} ${product.name}`;
    const textNeedsTrimming = text.length > 90;
    const trimmedText = `${text.substring(0, 90)}...`;
    return {
      id: product.id,
      label: (
        <NewOrderToolTip title={textNeedsTrimming ? text : ""}>
          <Typography variant="body1">{textNeedsTrimming ? trimmedText : text}</Typography>
        </NewOrderToolTip>
      ),
    };
  };

  return (
    <Grid container direction="column">
      <Grid item className={"mb-4"}>
        <WizardStepHeader text={t("orders.new.wizard.product.select")} />
      </Grid>
      {isLoading && (
        <Grid item>
          <LinearProgress />
        </Grid>
      )}
      {doDisplaySearchField() && (
        <Grid item className={classes.searchContainer}>
          <SearchField
            size="large"
            tooltip={t("orders.new.wizard.search.products")}
            onSearchSubmit={(value: string) => {
              setSearchText(value);
            }}
            placeholder={t("orders.new.wizard.search.products")}
            autoFocus={false}
          />
        </Grid>
      )}
      {!isLoading &&
        products &&
        products.map((product) => {
          return (
            <Grid key={product.id} item className={classes.productContainer}>
              <ProductInfoBox
                displayData={getProductDisplayData(product)}
                onClick={() => onProductClicked(product)}
                isSingleOrder={product.articleType === CfmProductArticleType.SingleOrderProduct}
              />
            </Grid>
          );
        })}

      {hasNoData() && (
        <Grid item className={"mt-3 mb-2"}>
          <Typography variant="body1">{t("orders.new.wizard.noData")}</Typography>
        </Grid>
      )}
    </Grid>
  );
};
