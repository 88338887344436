import { Dialog, DialogContent } from "@material-ui/core";
import { FC, ReactNode } from "react";
import { AppDialogButtonRow } from "./app-dialog-button-row.component";
import { AppDialogHeader } from "./app-dialog-header.component";
import { DialogLoadingContainer } from "./dialog-loading-container";
import { useDialogStyles } from "./dialog.styles";

interface IAppDialogProps {
  title?: string;
  id?: string | number;
  subtitle?: string | ReactNode;
  headerLabelComponent?: JSX.Element;
  onCancelClick?: VoidFunction;
  onAcceptClick?: VoidFunction;
  onCompleteClick?: VoidFunction;
  open: boolean;
  acceptTextOverride?: string;
  cancelTextOverride?: string;
  completeTextOverride?: string;
  fullWidth?: boolean;
  isLoading?: boolean;
  maxWidth?: false | "sm" | "xs" | "md" | "lg" | "xl" | undefined;
  acceptDisabled?: boolean;
  isCompleteVisible?: boolean;
  isAcceptVisible?: boolean;
  displayStyle?: "success" | "warning";
  alignButtons?: "center" | "space-between";
}

export const AppDialog: FC<IAppDialogProps> = (props) => {
  const {
    onCancelClick,
    onAcceptClick,
    onCompleteClick,
    open,
    children,
    cancelTextOverride,
    acceptTextOverride,
    completeTextOverride,
    fullWidth = true,
    isAcceptVisible = true,
    isLoading,
    title,
    subtitle,
    headerLabelComponent,
    maxWidth = "md",
    acceptDisabled,
    isCompleteVisible,
    displayStyle,
    id,
    alignButtons = "center",
  } = props;
  const classes = useDialogStyles();

  return (
    <Dialog open={open} maxWidth={maxWidth} fullWidth={fullWidth}>
      <AppDialogHeader
        title={title}
        subtitle={subtitle}
        id={id}
        displayStyle={displayStyle}
        children={headerLabelComponent}
      />
      {isLoading && <DialogLoadingContainer />}
      {!isLoading && (
        <DialogContent className={classes.dialogContent}>
          <>
            {children}
            <AppDialogButtonRow
              onCancelClick={onCancelClick}
              onAcceptClick={onAcceptClick}
              onCompleteClick={onCompleteClick}
              cancelTextOverride={cancelTextOverride}
              acceptTextOverride={acceptTextOverride}
              completeTextOverride={completeTextOverride}
              acceptDisabled={acceptDisabled}
              isCompleteVisible={isCompleteVisible}
              isAcceptVisible={isAcceptVisible}
              alignButtons={alignButtons}
              type="button"
            />
          </>
        </DialogContent>
      )}
    </Dialog>
  );
};
