import { TranslateFunc } from "utils/translation.utils";
import { IAddress } from "shared/domain/address/address";
import { IGroup } from "shared/domain/group/group";
import { GroupType } from "../group/group-type";
import { IPaginationMeta } from "../pagination/pagination-meta";
import { Language } from "./language";
import { getRolePomTranslation, RolePom } from "./role-pom";
import { getUserTypeLevelTranslation, UserTypeLevel } from "./user-type-level";

export enum ResetPasswordStatus {
  Successful = "Successful",
  NoUser = "NoUser",
  WrongOldPass = "WrongOldPass",
  Failed = "Failed",
  CaptchaFailed = "CaptchaFailed",
}

export interface IUser {
  id: number;
  uuid: string;
  active: Date | null;
  deliveryEmail: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  jobTitle: string;
  language: Language;
  telephone: string;
  title: string;
  group?: IGroup;
  mustResetPassword: boolean;
  userTypeLevel: UserTypeLevel | null;
  externalId: string;
  addresses?: IAddress[];
  notify?: boolean;
  rolePom?: RolePom;
  createdAt?: Date;
  lastLogin: Date | null;
}

export interface IUserLoginAs {
  firstName: string | undefined;
  lastName: string | undefined;
  id: number;
}

export interface IUserResult {
  users: IUser[];
  meta?: IPaginationMeta;
}

export interface IResetPasswordResponse {
  message: string;
  status: ResetPasswordStatus;
}

export function userHasType(user: IUser | undefined, type: GroupType | undefined): boolean {
  if (!user || !type) {
    return false;
  }

  return user.group?.type === type;
}

// Shared
export function isAdmin(user: IUser | undefined): boolean {
  return userHasType(user, GroupType.Admin);
}

// CFM
export function isCfm(user: IUser | undefined): boolean {
  return isAdmin(user) || isCfmCustomer(user) || isCfmRecycler(user) || isCfmLogistics(user);
}

export function isCustomer(user: IUser | undefined): boolean {
  return userHasType(user, GroupType.Customer);
}

export function isCfmCustomer(user: IUser | undefined): boolean {
  return userHasType(user, GroupType.Customer) && (user?.group?.cfmContracts?.length ?? 0) > 0;
}

export function isCfmLogistics(user: IUser | undefined): boolean {
  return userHasType(user, GroupType.CfmLogistics);
}

export function isCfmRecycler(user: IUser | undefined): boolean {
  return userHasType(user, GroupType.CfmRecycler);
}

// POM
export function isPom(user: IUser | undefined): boolean {
  return isAdmin(user) || isPomUser(user) || isPomRepresentative(user);
}

export function isPomAgency(user: IUser | undefined): boolean {
  return userHasType(user, GroupType.PomAgency);
}

export function isPomUser(user: IUser | undefined): boolean {
  return userHasType(user, GroupType.Customer) && (user?.group?.pomContracts?.length ?? 0) > 0;
}

export function isPomRepresentative(user: IUser | undefined): boolean {
  return userHasType(user, GroupType.PomRepresentative);
}

// roles
export const isCfmCorporate = (user: IUser | undefined): boolean => {
  return !!user && user.userTypeLevel === UserTypeLevel.Corporate;
};

export const isCfmWasteProducer = (user: IUser | undefined): boolean => {
  return !!user && user.userTypeLevel === UserTypeLevel.WasteProducer;
};

export function isPomMainContact(user: IUser | undefined): boolean {
  return (isPomUser(user) || isPomAgency(user) || isPomRepresentative(user)) && user?.rolePom === RolePom.MainContact;
}

export function isPomMainContactOrCfmCorporate(user: IUser | undefined): boolean {
  return isPomMainContact(user) || (isCfmCorporate(user) && isCfmCustomer(user));
}

// misc
export const getGroupName = (user: IUser): string => {
  return user.group?.name ?? "";
};

export const getFullName = (user: IUser): string => {
  return `${user.firstName ?? ""} ${user.lastName ?? ""}`;
};

export const getCfmRoleName = (user: IUser, translate: TranslateFunc): string => {
  return user.userTypeLevel ? translate(getUserTypeLevelTranslation(user.userTypeLevel)) : "-";
};

export const getPomRoleName = (user: IUser, translate: TranslateFunc): string => {
  if (!user.rolePom) return "";
  return translate(getRolePomTranslation(user.rolePom));
};
